



















import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'UOttawaPreLab5',
  components: {AiLoadingOverlay, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part1: '',
      },
      questions: [
        {
          text: '1) The rate law for the reaction between catalase and hydrogen peroxide can be written as Rate = $\\ce{k [H2O2]^x [catalase]^y}$. A catalase solution of unknown, but constant concentration is prepared. Can the partial order with respect to the peroxide (x) be found using this catalase solution? If so, how would you do it? Describe in detail the steps you would take or show a calculation.',
          inputName: 'part1',
        },
      ],
    };
  },
};
